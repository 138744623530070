@font-face {
  font-family: "ED Mentasta-Regular";
  src: local("ED Mentasta"), url("./fonts/ED Mentasta/ED\ Mentasta-Regular.ttf");
}

@font-face {
  font-family: "Gothic_A1";
  src: local("Gothic_A1"), url("./fonts/Gothic_A1/GothicA1-Light.ttf");
}

* {
  margin: 0;
  padding: 0;
}

body {
  font-family: "Gothic_A1", sans-serif;
}

.container {
  display: flex;
  flex-direction: column;
  margin: 10px;
  min-height: 400px;
  border-radius: 5px;
}

.textBoxesContainer {
  display: flex;
  margin-top: 20px;
  border-radius: 5px;
  text-align: center;
}

.textBoxContainer {
  display: flex;
  flex-direction: column;
  margin: 20px;
  border-radius: 5px;
  text-align: center;
}

.textBox {
  background-color: #d3bbf2;
  color: #000;
  border-radius: 5px;
  padding: 9px;
  text-align: center;
  vertical-align: top;
}

.textBoxTitle {
  display: grid;
  height: 40px;
}

.page {
  display: flex;
  margin: 30px auto;
}

.pageColumn {
  flex: 1;
  font-family: "Gothic A1", sans-serif;
  width: 50%;
}

p {
  margin-bottom: 1em;
}

.p-top-space {
  margin-bottom: 1em;
  margin-top: 60px;
}

.frontPage {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 10px 10px;
  min-height: 400px;
  padding: 10px;
  border-radius: 5px;
  align-items: center;
}

.header {
  display: flex;
  width: 100%;
  align-self: center;
  position: sticky;
  flex-direction: column;
  top: 0;
  z-index: 100;
  background-color: white;
  font-family: "ED Mentasta-Regular", sans-serif;
  font-size: 1.25em;
}

.header-container {
  display: flex;
  align-items: flex-start;
  position: sticky;
  top: 0;
  z-index: 100;
  background-color: white;
  font-family: "ED Mentasta-Regular", sans-serif;
}

@media screen and(min-width: 500px) {
  /* @media only screen and (max-width: 600px) { */
  div.header {
    /* font-size: 1.75; */
  }
}

.page-title {
  font-family: "ED Mentasta-Regular", sans-serif;
  top: 20rem;
  z-index: 1;
  margin-bottom: 10px;
}

.bsticky {
  position: sticky;
  top: 22rem;
  z-index: 1;
}

.headerLogo {
  display: flex;
  justify-content: space-between;
  align-self: center;
  margin-bottom: 10px;
  margin-left: 78px;
}

.nameAndLogo {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 2;
  margin-bottom: 1rem;
  font-family: "ED Mentasta-Regular", sans-serif;
  font-size: 2.8em;
}

.emailAndPhone {
  color: #a973f0;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.small-font {
  font-size: 14px;
}

.frontPagePic {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 1rem;
  min-height: 200px;
  width: 100%;
}

.otherPagePic {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 1rem;
}

.regulationsPage {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.row {
  line-height: 2em;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.row .left {
  -webkit-order: 1;
  -moz-order: 1;
  -ms-order: 1;
  order: 1;
}
.row .right {
  -webkit-order: 3;
  -moz-order: 3;
  -ms-order: 3;
  order: 3;
}

.row:after {
  content: "";
  margin: 0px 4px;
  background: -webkit-radial-gradient(
    50% 50%,
    circle,
    black 12%,
    transparent 15%
  );
  background: -moz-radial-gradient(50% 50%, circle, black 12%, transparent 15%);
  background: radial-gradient(circle at 50% 50%, black 12%, transparent 15%);
  background-size: 1em 1em;
  background-position: 0 0.5em;
  background-repeat: repeat-x;
  -webkit-flex-grow: 1;
  -moz-flex-grow: 1;
  -ms-flex-grow: 1;
  flex-grow: 1;
  -webkit-order: 2;
  -moz-order: 2;
  -ms-order: 2;
  order: 2;
}

.btn {
  display: inline-block;
  background: #a973f0;
  color: #fff;
  height: 35px;
  border: none;
  padding: 10px 20px;
  margin: 5px;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  font-size: 15px;
  font-family: "Amita";
}

.menuButton {
  background: #a973f0;
  color: #fff;
  border: none;
  padding: 10px 20px;
  margin: 5px 5px 5px 5px;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  font-size: 12px;
  font-family: "Amita";
  height: 40px;
  z-index: 500;
}

.menuBox {
  background-color: #d3bbf2;
  color: #fff;
  padding: 10px 20px;
  margin: 5px;
  border-radius: 25px;
}

.btn:focus {
  outline: none;
}

.btn:active {
  transform: scale(0.98);
}

.btn-block {
  display: block;
  width: 100%;
}

footer {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  text-align: center;
  justify-content: space-between;
  font-size: 12px;
  position: relative;
  bottom: 0;
}

.socialLinks {
  display: flex;
  color: #a973f0;
  margin-top: 30px;
  margin-bottom: 30px;
  margin-left: 10px;
  margin-right: 30px;
  width: 90%;
  justify-content: space-around;
}
